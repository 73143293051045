import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
//   import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
// import { Line } from 'react-chartjs-2';
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Filler,
//     Legend,
// } from 'chart.js';

// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Filler,
//     Legend
// );

export default function AreaChart2(props) {
  const options = {
    //     responsive: true,
    //     plugins: {
    //       legend: {
    //         position: 'top',
    //       },
    //       title: {
    //         display: true,
    //         text: 'Chart.js Bar Chart',
    //       },
    //     },
    //   };
    //     type : 'bar',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 9,
            family: "vazir",
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 12,
            family: "vazir",
          },
        },
        beginAtZero: true,
        // max: 100,
        // max: props.maxValueArea,
      },
    },
  };
  const labels = props.labels;
  const labelTemp = props.labelname1;
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: labelTemp,
        //   data: [10,42,13,74,25,23,54,65],
        data: props.temp,
        borderColor: "rgb(211, 84, 0 )",
        backgroundColor: props.backColor,
        // backgroundColor: 'rgba(237, 187, 153, 0.7)',
        radius: 3,
        borderJoinStyle: "round",
      },
    ],
  };
  return (
    <>
      <Bar options={options} data={data} height={285} width={350} />
      {/* <Line options={options} data={data} height={285} width={350}/> */}
    </>
  );
}
