import { DateRangePicker } from "rsuite";
import React, { useState, useEffect } from "react";
import "./MachineData.css";
import { useNavigate, useLocation } from "react-router-dom";
// import { finddate } from '../../Service/FindByDateApi'
import moment from "moment";
import Header from "../Common/Header/Header";
import GuageChart1 from "../Common/Charts/GaugeChart1/GuageChart1";
import Clock from "react-live-clock";
import { GetMeterData } from "../../Service/MeterApi";
import Footer from "../Common/Footer/Footer";
import Header2 from "../Common/Header/Header2";
import AreaChart1 from "../Common/Charts/AreaChart1/AreaChart1";
import AreaChart2 from "../Common/Charts/AreaChart1/AreaChart2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MachineData = () => {
  const [value, setValue] = useState([]);

  let new1 = moment(value[0])?.format("DD-MM-YYYY");
  let new2 = moment(value[1])?.format("DD-MM-YYYY");

  let sdate = moment(value[0])?.format("YYYY-MM-DD");
  let edate = moment(value[1])?.format("YYYY-MM-DD");

  let sendDate = {
    startDate: new1,
    endDate: new2,
  };
  let dateback = {
    startDate: sdate,
    endDate: edate,
  };
  const navigate = useNavigate();
  const location = useLocation();

  let MQTT_ID = new URLSearchParams(location.search).get("MQTT_ID");
  // let roomName = location?.state?.roomName
  const [meterDashData, setMeterDashData] = useState([]);
  let getarray = meterDashData?.map((array) => {
    return array?.meterReadings;
  });

  // this api is goes to get api of meters data
  const gettingMeterData = async () => {
    let res = await GetMeterData(MQTT_ID);
    if (res.error != null) {
      toast.error(res.error);
    } else {
      setMeterDashData(res.data);
    }
  };

  useEffect(() => {
    if (MQTT_ID) {
      // setInterval(() => {
      gettingMeterData();
      // }, 5000);
    }
  }, [MQTT_ID]);

  const settingDate = (event) => {
    if (event == null) {
      setValue([]);
    } else {
      setValue(event);
    }
  };

  // the function is use to find the date between start date and last date
  const findByDate = () => {
    let startDate = dateback?.startDate;
    let endDate = dateback?.endDate;
    let sDate = new Date(startDate).getTime();
    let eDate = new Date(endDate).getTime();
    let epStartDate = new Date(sDate - 18000000).getTime();
    let epEndDate = new Date(eDate + 68000000).getTime();
    let filterDate = meterDashData?.map((meter) => {
      return {
        ...meter,
        meterReadings: meter?.meterReadings.filter((val) => {
          let currenDate = new Date(val?.datetime).getTime();
          if (currenDate >= epStartDate && currenDate <= epEndDate) {
            return true;
          } else {
            return false;
          }
        }),
      };
    });

    navigate("/dashboard/report", {
      state: { date: sendDate, filterDate: filterDate },
    });
  };

  return (
    <>
      <div className="meterdatamain">
        <Header
          timedate={
            <Clock
              format={"HH:mm:ss| DD-MM-YYYY"}
              ticking={true}
              timezone={"asia/Karachi"}
            />
          }
        />
        <Header2
          Device_ID={meterDashData[0]?.Device_ID}
          updatetime={
            getarray[0]?.[getarray[0]?.length - 1]?.time +
            "\t | \t" +
            getarray[0]?.[getarray[0]?.length - 1]?.date
          }
        />
        <div className="machinedatamain">
          <div className="machinetabledata"></div>
        </div>
        <div className="gatherdateID">
          <div className="datepiker">
            <DateRangePicker
              showOneCalendar
              className="rangepiker"
              onChange={(event) => settingDate(event)}
              value={value}
              placeholder="Start Date ~ End Date"
              renderValue={(value) => {
                return (
                  moment(value[0])?.format("DD-MM-YYYY") +
                  " ~ " +
                  moment(value[1])?.format("DD-MM-YYYY")
                );
              }}
            />
            <button className="btnreport" onClick={findByDate}>
              
              Report
            </button>
          </div>
        </div>
        <div className="allcharts">
          <div className="getherCharts">
            <div className="guageChartcontainer">
              <div className="chartarea1">
                <div className="gaugechartarea1">
                  <div className="gaugechartarea ">
                    {/* rgba(237, 18, 1, 1)  g2 rgba(255,69,0,0.4) g3 rgba(2, 18, 153, 1) g4rgba(2, 18, 153, 1) g5 rgba(2, 18, 153, 1)*/}
                    <GuageChart1
                      val={
                        (getarray[0]?.[getarray[0]?.length - 1]?.co / 1000) *
                        100
                      }
                      name={parseInt(
                        getarray[0]?.[getarray[0]?.length - 1]?.co
                      )}
                      numColor="rgb(0,0,255)"
                      textColor="rgb(0,0,255)"
                      heightgraph={180}
                      valpercent={"%"}
                      gradientColor={"#006400"}
                      textFontSize="18px"
                      valueFontSize="0px"
                      valfix={0}
                    />
                    <div className="gaugeName">CO ppm</div>
                  </div>
                  <div className="gaugechartarea ">
                    <GuageChart1
                      val={
                        (getarray[0]?.[getarray[0]?.length - 1]?.sox / 500) *
                        100
                      }
                      name={parseInt(
                        getarray[0]?.[getarray[0]?.length - 1]?.sox
                      )}
                      numColor="rgb(0,0,255)"
                      textColor="rgb(0,0,255)"
                      heightgraph={180}
                      valpercent={"ppm"}
                      gradientColor={"#9ACD32"}
                      textFontSize="18px"
                      valueFontSize="0px"
                      valfix={0}
                    />
                    <div className="gaugeName">SOX ppm</div>
                  </div>
                </div>
                <div className="linechart1">
                  <AreaChart1
                    labels={getarray[0]?.slice(-12)?.map((time) => time?.time)}
                    temp={getarray[0]?.slice(-12)?.map((time) => time?.co)}
                    humid={getarray[0]?.slice(-12)?.map((time) => time?.sox)}
                    labelname1="SOX"
                    labelname2="CO"
                    maxValueArea={60}
                    backColor1="	#006400"
                    backColor2="#9ACD32"
                  />
                </div>
              </div>
              <div className="chartarea2">
                <div className="gaugechartarea2">
                  <div className="gaugechartarea">
                    <GuageChart1
                      val={
                        (getarray[0]?.[getarray[0]?.length - 1]?.nox / 10) * 100
                      }
                      name={parseFloat(
                        getarray[0]?.[getarray[0]?.length - 1]?.nox
                      ).toFixed(1)}
                      numColor="rgb(0,0,255)"
                      textColor="rgb(0,0,255)"
                      heightgraph={180}
                      valpercent={"ppm"}
                      gradientColor={"#0000FF"}
                      textFontSize="18px"
                      valueFontSize="0px"
                      valfix={2}
                    />
                    <div className="gaugeName">NOX ppm</div>
                  </div>
                  <div className="gaugechartarea">
                    <GuageChart1
                      val={
                        (getarray[0]?.[getarray[0]?.length - 1]?.co2 / 258) *
                        100
                      }
                      name={parseFloat(
                        getarray[0]?.[getarray[0]?.length - 1]?.co2
                      ).toFixed(1)}
                      numColor="rgb(0,0,255)"
                      textColor="rgb(0,0,255)"
                      heightgraph={180}
                      valpercent={" %"}
                      gradientColor={"#87CEEB"}
                      textFontSize="18px"
                      valueFontSize="0px"
                      valfix={0}
                    />
                    <div className="gaugeName">CO2 %</div>
                  </div>
                </div>
                <div className="linechart2">
                  <AreaChart1
                    labels={getarray[0]?.slice(-12)?.map((time) => time?.time)}
                    temp={getarray[0]?.slice(-12)?.map((time) => time?.co2)}
                    humid={getarray[0]?.slice(-12)?.map((time) => time?.nox)}
                    labelname1="CO2"
                    labelname2="NOX"
                    maxValueArea={10}
                    backColor1="#0000FF"
                    backColor2="#87CEEB"
                  />
                </div>
              </div>
              <div className="chartarea3">
                <div className="gaugechartarea hourschart">
                  <GuageChart1
                    val={
                      (getarray[0]?.[getarray[0]?.length - 1]?.pm / 100) * 100
                    }
                    name={parseFloat(
                      getarray[0]?.[getarray[0]?.length - 1]?.pm
                    ).toFixed(1)}
                    numColor="rgb(0,0,255)"
                    textColor="rgb(0,0,255)"
                    heightgraph={180}
                    valpercent={"ppm"}
                    gradientColor={"#696969"}
                    textFontSize="18px"
                    valueFontSize="0px"
                  />
                  <div className="gaugeName">PM ug/m3</div>
                </div>
                <div className="linechart3">
                  <AreaChart2
                    labels={getarray[0]?.slice(-12)?.map((time) => time?.time)}
                    temp={getarray[0]?.slice(-12)?.map((time) => time?.pm)}
                    labelname1="Particular Matter"
                    maxValueArea={10}
                    backColor="#696969"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
};
export default MachineData;
